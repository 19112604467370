export const DocumentCoursesTableColumns = [
  {
    text: "Kurs-ID",
    value: "courseId",
  },
  {
    text: "Handlinger",
    value: "actions",
  },
  {
    text: "Kursnavn",
    value: "courseName",
  },
  {
    text: "Lokasjon",
    value: "placeName",
  },
  {
    text: "Kategori",
    value: "category",
  },
  {
    text: "Beskrivelse",
    value: "description",
  },
  {
    text: "Filnavn",
    value: "originalFileName",
  },
  {
    text: "Type",
    value: "mimeType",
  },
  {
    text: "Opplastingsdato",
    value: "inserted",
  },
];

export const DocumentUserTableColumns = [
  {
    text: "ID",
    value: "courseId",
  },
  {
    text: "Handlinger",
    value: "actions",
  },
  {
    text: "Kurs/studie",
    value: "courseName",
  },
  {
    text: "Lokasjon",
    value: "placeName",
  },
  {
    text: "Kategori",
    value: "category",
  },
  {
    text: "Beskrivelse",
    value: "description",
  },
  {
    text: "Filnavn",
    value: "originalFileName",
  },
  {
    text: "Opplastingsdato",
    value: "inserted",
  },
];

export const CourseInstructorTableColumns = [
  {
    text: "Id",
    value: "courseId",
  },
  {
    text: "Handlinger",
    value: "action",
  },
  {
    text: "Tittel",
    value: "course.externalTitle",
  },
  {
    text: "Start",
    value: "course.startDate",
  },
  {
    text: "Slutt",
    value: "course.endDate",
  },
  {
    text: "Status",
    value: "course.status",
  },
  {
    text: "Min status",
    value: "status",
  },
];

export const CourseParticipantTableColumns = [
  {
    text: "Tittel",
    value: "course.externalTitle",
  },
  {
    text: "Handlinger",
    value: "action",
  },
  {
    text: "Start",
    value: "course.startDate",
  },
  {
    text: "Slutt",
    value: "course.endDate",
  },
  {
    text: "Status",
    value: "course.status",
  },
  {
    text: "Min status",
    value: "status",
  },
  {
    text: "Avmeldingsfrist",
    value: "course.cancellationDeadline",
  },
];

export const CourseHoursTableColumns = [
  {
    text: "Dato",
    value: "date",
  },
  {
    text: "Klasse timer",
    value: "hours",
  },
  {
    text: "Tittel",
    value: "title",
  },
  {
    text: "Type",
    value: "pdName",
  },
  {
    text: "Undertype",
    value: "pdSubTypeName",
  },
  {
    text: "Registrert timer",
    value: "registeredHours",
  },
  {
    text: "Godkjente timer",
    value: "approvedHours",
  },
  {
    text: "Kommentar",
    value: "comment",
  },
];

export const CourseAttendanceTableColumns = [
  {
    text: "Dato",
    value: "date",
  },
  {
    text: "Type",
    value: "title",
  },
  {
    text: "Utfylt",
    value: "completed",
  },
  {
    text: "Status",
    value: "status",
  },
  {
    text: "Kommentar",
    value: "comment",
  },
];

export const AttendanceDayTableColumns = [
  {
    text: "Navn",
    value: "name",
  },
  {
    text: "Timer",
    value: "hours",
  },
  {
    text: "Kommentar",
    value: "comment",
  },
];

export const HoursTableColumns = [
  {
    text: "Kursnavn",
    value: "name",
  },
  {
    text: "Dato",
    value: "date",
  },
  {
    text: "Tittel",
    value: "title",
  },
  {
    text: "Type",
    value: "pdName",
  },
  {
    text: "Undertype",
    value: "pdSubTypeName",
  },
  {
    text: "Registrerte timer",
    value: "registeredHours",
  },
  {
    text: "Godkjente timer",
    value: "approvedHours",
  },
  {
    text: "Kommentar",
    value: "comment",
  },
];

export const AttendanceTableColumns = [
  {
    text: "Navn",
    value: "name",
  },
  {
    text: "Dato",
    value: "date",
  },
  {
    text: "Type",
    value: "title",
  },
  {
    text: "Utfylt",
    value: "completed",
  },
  {
    text: "Kommentar",
    value: "comment",
  },
];
