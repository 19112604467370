
import BreadCrumb from "@/components/shared/breadcrumbs/BaseBreadCrumbs.vue";
import { FormatList } from "@/shared/helpers/filterHelpers";
import { CourseInstructorTableColumns, CourseParticipantTableColumns } from "@/shared/utils/tableHeaders";
import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "CourseListPage",
  components: { BreadCrumb },
  data: function () {
    return {
      search: "",
      courseStatus: "åpent",
    };
  },
  computed: {
    ...mapGetters("hoc", ["getResourceMode"]),
    ...mapGetters("course", ["getCourses"]),

    tableHeaders() {
      if ((this as any).getResourceMode) {
        return FormatList(CourseInstructorTableColumns, "text");
      }
      return FormatList(CourseParticipantTableColumns, "text");
    },
  },
  methods: {
    ...mapActions("course", ["fetchCourses"]),
    handleClick(value: any) {
      this.$router.push(`/course/${value.id}`);
    },
  },
  async mounted() {
    if ((!this as any).getCourses.length) {
      await (this as any).fetchCourses();
    }
  },
});
