var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('bread-crumb'), _vm.getCourses.length <= 0 ? _c('div', {
    staticClass: "title font-weight-light text-center",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" Ingen kurs tilgjengelig ")]) : _c('v-card', {
    attrs: {
      "elevation": 1
    }
  }, [_c('v-card-title', [_vm._v(" Min kompetanse "), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Søk",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-spacer'), _c('v-data-table', {
    attrs: {
      "headers": _vm.tableHeaders,
      "items": _vm.getCourses,
      "search": _vm.search
    },
    on: {
      "click:row": _vm.handleClick
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }